
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const listCheckboxVal = ref([])
    const switchValue = ref(false)
    const radio = ref('')

    return {
      listCheckboxVal,
      switchValue,
      radio,
    }
  },
})
